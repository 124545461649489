import Layout from 'components/Layout';
import React, { useState, useEffect } from 'react';
import Col from 'components/Col';
import Row from 'components/Row';
import { useQuery } from '@apollo/client';
import styled, { css } from 'styled-components';
import { getTicket } from 'services/hasura';
import EventHeader from 'components/EventHeader';
import EventAnimation from 'components/EventAnimation';
import { useQueryParam, StringParam } from 'use-query-params';
import { Router, useParams } from '@reach/router';

const VideoContainer = styled.div`
  height: 60vh;
`;

const Iframe = styled('iframe')`
  min-height: 50vh;
`;
const Stream = ({ ticket }) => (
  <Col>
    <VideoContainer>
      <iframe
        src={`https://vimeo.com/event/${ticket.stream}/embed`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        className="w-full h-full"
        title="New event"
      />
    </VideoContainer>
    <Col>
      <Iframe
        src={`https://vimeo.com/event/${ticket.stream}/chat/`}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </Col>
  </Col>
);

function StreamContainer() {
  const params = useParams();
  const v = {
    data: {
      ticket: [
        {
          id: '1050-1-1',
          name: 'Donation - 300 KES',
          stream: '1419307',
          event: {
            id: 29,
            name: 'TWA Virtual Event',
            description: 'b64:',
            event_type: 'streamed',
            image: 'https://cdn.tutadoo.com/img/x443hvfk.ecb.jpg',
            start_date: '2021-10-31T12:00:00+00:00',
            end_date: '2021-10-31T14:30:00+00:00',
            status: 'published',
            category: 'Webinar',
            social: { twitter: '', instagram: '', facebook: '' },
            organizer: {
              id: 319,
              name: 'Twakutukuza Trust (TWA)',
              user_id: 'auth0|616d2aeb33f69200704ad42a',
              __typename: 'organizers'
            },
            __typename: 'events'
          },
          __typename: 'Tickets'
        }
      ]
    }
  };
  const [ticket, setTicket] = useState(v.data.ticket[0]);
  const resp: { data: namespace.Data; loading: boolean } = useQuery(getTicket(params.id));
  useEffect(() => {
    if (!resp.loading) {
      //   setTicket(resp.data.ticket[0]);
    }
  }, [resp.loading]);
  return (
    <Col>
      {resp.loading || ticket === null ? <EventAnimation /> : <Stream ticket={ticket} />}
      {ticket?.event && <EventHeader event={ticket.event} />}
    </Col>
  );
}

export default function stream() {
  return (
    <Layout>
      <script src="https://player.vimeo.com/api/player.js" />
      <Router basepath="/stream">
        <StreamContainer path=":id" />
        <StreamContainer path="/:id" />
        <StreamContainer path=":id/" />
      </Router>
    </Layout>
  );
}
